//@ts-ignore
import { Menu, Dropdown } from 'antd'
import userlogo from '../Assets/Img/userLogo.svg'
//@ts-ignore
import { utility } from '@tdt-global/utility'

export const User = () => {
    const { logout, useUser } = utility
    let { user, isLoading, isError } = useUser()

    const handleLogout = () => {
        var stateObject = { destiny: '/suite-apps' }
        history.pushState(stateObject, 'Suite-apps', '/suite-apps')
        logout()
    }

    const menuUser = (
        <Menu>
            <Menu.Item
                key='1'
                onClick={handleLogout}
                style={{ textAlign: 'center' }}>
                <span>Cerrar sesión</span>
            </Menu.Item>
        </Menu>
    )
    return (
        <Dropdown overlay={menuUser} trigger={['click']}>
            <div className='rigth-item user ant-dropdown-link'>
                <div className='icon'>
                    <img
                        alt='logo switch'
                        className='icon-navbar'
                        src={userlogo}
                    />
                </div>
                <div className='username'>{`${
                    isLoading ? '' : user?.first_name
                } ${isLoading ? '' : user?.last_name}`}</div>
            </div>
        </Dropdown>
    )
}
