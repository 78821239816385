//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useLocation } from 'react-router-dom'
import { Logo } from './Logo'
import { User } from './User'

const ROUTES = {
    LOGIN: '/login',
    SUITE_APPS: '/suite-apps',
    DASHBOARD: '/',
}

const Navbar = ({ ...props }) => {
    const { auth$ } = utility
    let location = useLocation()

    return (
        <>
            {auth$.getValue().sessionToken && (
                <div
                    id='tdt-global-navbar'
                    className={
                        location.pathname === ROUTES.SUITE_APPS
                            ? 'is-suite-apps'
                            : ''
                    }>
                    <div className='left'>
                        <Logo />
                    </div>
                    <div className='right'>
                        {/* <Dropdown overlay={NotificationPanel} trigger={["click"]}>
              <div className="rigth-item bell ant-dropdown-link">
                <Badge className="badge-bell" count={8}>
                  <BellOutlined className="icon-navbar" />
                </Badge>
              </div>
            </Dropdown> */}
                        <User />
                        {/* <Dropdown overlay={menuTraslate} trigger={["click"]}>
              <div className="rigth-item traslate ant-dropdown-link">
                <GlobalOutlined className="icon-navbar" />
              </div>
            </Dropdown> */}
                    </div>
                </div>
            )}
        </>
    )
}

export default Navbar
