import { BrowserRouter as Router } from 'react-router-dom'

import Container from './Components/Container'

export default function Root(props) {
    return (
        <>
            <Router>
                <Container />
            </Router>
        </>
    )
}
