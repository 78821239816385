import { MenuType } from '../Types/MenuType'

export class MenuModel {
    private _id: number
    private _description: string | null
    private _icon: string | null
    private _name: string
    private _order: number
    private _parent_menu_id: any | null
    private _url: string | null
    private _type: string | null

    constructor(menu: MenuType) {
        this._id = menu.id
        this._name = menu.attributes.name
        this._description = menu.attributes.description
        this._order = menu.attributes.order
        this._parent_menu_id = menu.attributes.parent_menu_id
        this._icon = menu.attributes.icon
        this._url = menu.attributes.url
        this._type = menu.type
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get order(): number | null {
        return this._order
    }

    set order(value) {
        this._order = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get description(): string | null {
        return this._description
    }

    set description(value) {
        this._description = value
    }

    get parent_menu_id(): any {
        return this._parent_menu_id
    }

    set parent_menu_id(value) {
        this._parent_menu_id = value
    }

    get url(): string {
        return this._url
    }

    set url(value) {
        this._url = value
    }
    get icon(): string | null {
        return this._icon
    }

    set icon(value) {
        this._icon = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }
}
