import { useLocation } from 'react-router-dom'
import TDTLogo from '../Assets/Img/TDT-Logo-Color.svg'
import planningLogo from '../Assets/Img/Planning-Logo.svg'
import checkingLogo from '../Assets/Img/Checking-Logo.svg'
import availabilityLogo from '../Assets/Img/Availability-Logo.svg'
import inventoryLogo from '../Assets/Img/Inventory-Logo.svg'

import planningText from '../Assets/Img/Planning-Text.svg'
import checkingText from '../Assets/Img/Checking-Text.svg'
import availabilityText from '../Assets/Img/Availability-Text.svg'
import inventoryText from '../Assets/Img/Inventory-Text.svg'

import PlanningFavicon from '../Assets/Img/PlanningFavicon.png'
import AvailabilityFavicon from '../Assets/Img/AvailabilityFavicon.png'
import InventoryFavicon from '../Assets/Img/InventoryFavicon.png'
import CheckingFavicon from '../Assets/Img/CheckingFavicon.png'
import TDTFavicon from '../Assets/Img/TDT-Favicon.png'
import { useState } from 'react'
import { useEffect } from 'react'

import { Helmet } from 'react-helmet'

export const Logo = () => {
    let location = useLocation()
    const [logo, setLogo] = useState(null)
    const [name, setName] = useState(null)
    const [favicon, setFavicon] = useState(TDTFavicon)
    const [title, setTitle] = useState('TDT Global')
    console.log(logo)
    useEffect(() => {
        switch (location.pathname.split('/')[1]) {
            case 'suite-apps':
                setLogo(TDTLogo)
                setName('')
                setFavicon(TDTFavicon)
                setTitle('TDT Suite Apps')
                break
            case 'planning':
                setLogo(planningLogo)
                setName(planningText)
                setFavicon(PlanningFavicon)
                setTitle('TDT Planning')
                break
            case 'inventory':
                setLogo(inventoryLogo)
                setName(inventoryText)
                setFavicon(InventoryFavicon)
                setTitle('TDT Inventory')
                break
            case 'checking':
                setLogo(checkingLogo)
                setName(checkingText)
                setFavicon(CheckingFavicon)
                setTitle('TDT Checking')
                break
            case 'availability':
                setLogo(availabilityLogo)
                setName(availabilityText)
                setFavicon(AvailabilityFavicon)
                setTitle('TDT Availability')
                break
            default:
                break
        }
    }, [location])

    return (
        <div className='logo'>
            <Helmet>
                <title>{title}</title>
                <link
                    rel='icon'
                    type='image/png'
                    href={favicon}
                    sizes='16x16'
                />
            </Helmet>
            {logo &&
                (name ? (
                    <>
                        <img className='logotipo' alt='logo' src={logo} />
                        <img className='text' alt='text' src={name} />
                    </>
                ) : (
                    <img className='text' alt='text' src={logo} />
                ))}
        </div>
    )
}
