import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
//@ts-ignore
import { utility } from '@tdt-global/utility'

import Navbar from '../Components/Navbar'
import SideBar from '../Components/SideBar'

const ROUTES = {
    LOGIN: '/login',
    SUITE_APPS: '/suite-apps',
    DASHBOARD: '/',
}

function Container() {
    const [toggle, setToggle] = useState(false)
    const [authenticated, setAuthenticated] = useState<any>(false)
    const { auth$, useAxios, LocalStorage } = utility
    const { postRefreshToken } = useAxios(false, false, false)
    const [locationRouter, setLocationRouter] = useState<any>('')
    let navigate = useNavigate()
    let location = useLocation()

    const refreshFunction = useCallback(() => {
        postRefreshToken()
    }, [])

    useEffect(() => {
        let interval: any = ''
        if (authenticated)
            interval = setInterval(
                refreshFunction,
                LocalStorage.getExpirationTime(),
            )
        return () => clearInterval(interval)
    })

    useEffect(() => {
        if (window.location.pathname === '/') {
            setTimeout(() => {
                navigate(ROUTES.SUITE_APPS, { replace: true })
            }, 2000)
        }
    }, [])

    useEffect(() => {
        authenticated &&
            window.location.pathname === ROUTES.LOGIN &&
            RedirectAfterLogin()
    }, [authenticated])

    const RedirectAfterLogin = () => {
        // Redirecciona para la url que el usuario ingresa o en caso contrario va a suite apps
        locationRouter &&
        locationRouter !== ROUTES.LOGIN &&
        locationRouter !== ROUTES.DASHBOARD
            ? navigate(-1)
            : navigate(ROUTES.SUITE_APPS, { replace: true })
    }

    const RedirectToLogin = () => {
        if (window.location.pathname === '/') {
            setTimeout(() => {
                navigate(ROUTES.LOGIN, { state: locationRouter })
            }, 2000)
        } else {
            navigate(ROUTES.LOGIN, { state: locationRouter })
        }
    }

    useEffect(() => {
        let sub = auth$.subscribe(({ sessionToken }) => {
            const needsLogin = !sessionToken
            if (needsLogin && window.location.pathname !== ROUTES.LOGIN) {
                location.pathname !== ROUTES.LOGIN
                    ? setLocationRouter(location.pathname)
                    : setLocationRouter(location.state)
                setAuthenticated(false)
                RedirectToLogin()
            } else if (
                !needsLogin &&
                window.location.pathname === ROUTES.LOGIN
            ) {
                setAuthenticated(true)
            }
        })
        return () => {
            sub.unsubscribe()
        }
    }, [])
    return (
        <>
            {window.location.pathname !== '/' && (
                <>
                    <Navbar />
                    <SideBar toggle={toggle} setToggle={setToggle} />
                </>
            )}
        </>
    )
}

export default Container
