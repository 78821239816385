import { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
// @ts-ignore
import { Tooltip } from 'antd'
import IconsList from './IconsList'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Endpoints } from '../Config/Endpoint'
import { MenuModel } from '../Models/MenuModel'
import { MenuType } from '../Types/MenuType'

interface IProps {
    setToggle: (bool: boolean) => void
    toggle: boolean
}

const SideBar = ({ ...props }: IProps) => {
    const { setToggle, toggle } = props
    const [menuItems, setMenuItems] = useState<MenuModel[]>([])
    const { useAxios, auth$, LocalStorage } = utility
    const { getHttpRequest } = useAxios()
    let location = useLocation()

    useEffect(() => {
        let id = LocalStorage.getApplication() || null
        id &&
            getHttpRequest(
                Endpoints.GET_SIDEBAR.replace(':id', id),
                {},
                (respose) => {
                    let menu = respose.data.data.map(
                        (item) => new MenuModel(item),
                    )
                    setMenuItems(menu)
                },
            )
        return () => {}
    }, [LocalStorage])

    const RenderMenu = () => {
        let data = menuItems.map((item: MenuModel, index: any) => {
            let content: any = IconsList(item.icon)
            if (typeof content === 'string') {
                content = <img src={content} alt='icon-menu' />
            }
            let text = <div className='text-menu'>{item.name}</div>
            const tooltip = <span className='tooltip-menu'>{item.name}</span>

            const menuItem = (
                <span className={`cursor menu-item`}>
                    <div className='selected'></div>
                    <div className='item'>
                        {content}
                        {text}
                    </div>
                </span>
            )

            return (
                <NavLink
                    key={index}
                    to={item.url}
                    className={({ isActive }) =>
                        isActive ? 'menu-item-active' : undefined
                    }>
                    {!toggle ? (
                        <Tooltip
                            id='menu-tooltip'
                            placement='right'
                            color='#13023E'
                            key='#13023E'
                            trigger='hover'
                            title={tooltip}>
                            {menuItem}
                        </Tooltip>
                    ) : (
                        menuItem
                    )}
                </NavLink>
            )
        })
        return data
    }

    return (
        <>
            {auth$.getValue().sessionToken &&
                location.pathname !== '/suite-apps' && (
                    <div id='tdt-global-sidebar'>
                        <div
                            className={`backdrop ${toggle ? 'active' : ''}`}
                            onClick={() => setToggle(!toggle)}></div>
                        <div id='sidebar' className={toggle ? 'active' : ''}>
                            <div className='button-toggle'>
                                <div
                                    className={`icon-toggle ${
                                        toggle ? 'active' : ''
                                    }`}
                                    onClick={() => setToggle(!toggle)}
                                />
                            </div>
                            <div className='menu'>
                                {menuItems && RenderMenu()}
                            </div>
                            <div className='logo-tdt'>
                                <span>Un producto de</span>
                                <div className='logo'></div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    )
}

export default SideBar
